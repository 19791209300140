import axios from '@/utils/api.request'
import { apiUrl } from '@/utils/baseUrl';

/**标准版 */
//查VIN码
export const standardQueryVinForEpc = (data) => {
    return axios.post(`${apiUrl}/epc/api/standardQueryVinForEpc.mpi`, data);
};
//查询VIN码历史
export const standardGetEpcHistorySelectForVin = (data) => {
    return axios.post(`${apiUrl}/epc/api/standardGetEpcHistorySelectForVin.mpi`, data);
};
//查询VIN码历史
export const standardDeleteEpcHistorySelectForVin = (data) => {
    return axios.post(`${apiUrl}/epc/api/standardDeleteEpcHistorySelectForVin.mpi`, data);
};
//查询零件号
export const getInfoByOes = (data) => {
    return axios.post(`${apiUrl}/epc/api/getInfoByOes.mpi`, data);
};
//查询零件号历史
export const historySelectForOes = (data) => {
    return axios.post(`${apiUrl}/epc/api/historySelectForOes.mpi`, data);
};
//清除零件历史
export const deleteHistorySelectForOes = (data) => {
    return axios.post(`${apiUrl}/epc/api/deleteHistorySelectForOes.mpi`, data);
};
//查品牌件列表
export const getBrandList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getBrandList.mpi`, data);
};
//查品牌件
export const getBrandGoodsList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getBrandGoodsList.mpi`, data);
};
//标准版剩余次数查询
export const allSelectTimes = (data) => {
    return axios.post(`${apiUrl}/epc/api/allSelectTimes.mpi`, data);
};
//品牌全部品类
export const getAllProductList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getAllProductList.mpi`, data);
};
//品牌全部车型
export const getAllBrandList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getAllBrandList.mpi`, data);
};
//查品牌
export const getCarBrandList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getCarBrandList.mpi`, data);
};
//查车系
export const getCarServiceList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getCarServiceList.mpi`, data);
};
//查车型
export const getCarModelList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getCarModelList.mpi`, data);
};
//根据车标查品牌
export const getCarBrandListByLogoCode = (data) => {
    return axios.post(`${apiUrl}/epc/api/getCarBrandListByLogoCode.mpi`, data);
};

/**专业版 */
//查VIN码
export const queryVinForEpc = (data) => {
    return axios.post(`${apiUrl}/epc/api/queryVinForEpc.mpi`, data);
};
//查VIN码的历史记录
export const getEpcHistorySelect = (data) => {
    return axios.post(`${apiUrl}/epc/api/getEpcHistorySelect.mpi`, data);
};
//清空查VIN码的历史记录
export const deleteEpcHistorySelect = (data) => {
    return axios.post(`${apiUrl}/epc/api/deleteEpcHistorySelect.mpi`, data);
};
//查配置信息
export const getLlqCarInfo = (data) => {
    return axios.post(`${apiUrl}/epc/api/getLlqCarInfo.mpi`, data);
};
//查询EPC主图组
export const getLlqGroup = (data) => {
    return axios.post(`${apiUrl}/epc/api/getLlqGroup.mpi`, data);
};
//查询EPC二级图组
export const getLlqStructure = (data) => {
    return axios.post(`${apiUrl}/epc/api/getLlqStructure.mpi`, data);
};
//查询EPC三级图组
export const getLlqPart = (data) => {
    return axios.post(`${apiUrl}/epc/api/getLlqPart.mpi`, data);
};
//根据pid查询4s参考价格
export const getLlqPartPrice = (data) => {
    return axios.post(`${apiUrl}/epc/api/getLlqPartPrice.mpi`, data);
};
//获取套餐列表
export const getEsPackageList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getEsPackageList.mpi`, data);
};
//套餐购买
export const addBuyEsPackage = (data) => {
    return axios.post(`${apiUrl}/epc/api/addBuyEsPackage.mpi`, data);
};
//个人套餐购买记录
export const getBuyEsPackageList = (data) => {
    return axios.post(`${apiUrl}/epc/api/getBuyEsPackageList.mpi`, data);
};
//套餐确认支付
export const getPayUrl = (data) => {
    return axios.post(`${apiUrl}/epc/api/pay/getPayUrl.mpi`, data);
};
//套餐查询支付结果
export const getPayResult = (data) => {
    return axios.post(`${apiUrl}/epc/api/pay/getPayResult.mpi`, data);
};
//根据订单查订单信息
export const epcPackagePaymentPay = (data) => {
    return axios.post(`${apiUrl}/epc/api/pay/epcPackagePaymentPay.mpi`, data);
};

/*公共*/
//配件基本信息
export const getGoodsInfoById = (data) => {
    return axios.post(`${apiUrl}/epc/api/getGoodsInfoById.mpi`, data);
};
//适用车型
export const getCarModelInfoByGoodsId = (data) => {
    return axios.post(`${apiUrl}/epc/api/getCarModelInfoByGoodsId.mpi`, data);
};
//通用OE
export const getGeneralOes = (data) => {
    return axios.post(`${apiUrl}/epc/api/getGeneralOes.mpi`, data);
};
//替换件
export const getOeReplace = (data) => {
    return axios.post(`${apiUrl}/epc/api/getOeReplace.mpi`, data);
};
//供应商
export const listGoods = (data) => {
    return axios.post(`${apiUrl}/epc/api/listGoods.mpi`, data);
};
//立即购买
export const goBuy = (data) => {
    return axios.post(`${apiUrl}/epc/api/goBuy.mpi`, data);
};
