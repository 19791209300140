import axios from 'axios'
import qs from 'qs'
import {Dialog, Toast} from 'vant';
import md5 from 'js-md5'

class HttpRequest {
    constructor(baseUrl = '') {
        this.baseUrl = baseUrl;
        this.defaultParams = {
            appId: '1e28ew12e',
            appKey: 'c6d6f5293deb22906a15050b051552f9',
            validTime: '',
            sign: ''
        }
    }
    getInsideConfig() {
        var tokenuc = window.sessionStorage.getItem('token');
        const config = {
            baseURL: this.baseUrl,
            timeout: 5000,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };
        // config['headers']['Authorization'] = 'bearer ' + '6c328c40-a3ac-4d4d-8dba-4a89975331ba';
        if (tokenuc) {
            config['headers']['Authorization'] = 'bearer ' + tokenuc;
        }
        return config
    }
    interceptors(instance) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            return config
        }, error => {
            return Promise.reject(error)
        });
        // 响应拦截
        instance.interceptors.response.use(response => {
            if(response.data && response.data.status &&　response.data.status.code != 0){
                Dialog.alert({message:response.data.status.msg || '请求出现错误'})
                Toast.clear()
            }
            return response
        }, error => {
            let errorInfo = error.response;
            if (!errorInfo) {
                errorInfo = JSON.parse(JSON.stringify(error))
            } else {
                switch (errorInfo.status) {
                    case 302:
                        errorInfo.message = '302';
                        break;
                    case 400:
                        errorInfo.message = '错误请求';
                        break;
                    case 401:
                        errorInfo.message = '未授权，请重新登录';
                        break;
                    case 403:
                        errorInfo.message = '拒绝访问';
                        break;
                    case 404:
                        errorInfo.message = '请求错误,未找到该资源';
                        break;
                    case 405:
                        errorInfo.message = '请求方法未允许';
                        break;
                    case 408:
                        errorInfo.message = '请求超时';
                        break;
                    case 500:
                        errorInfo.message = '服务器端出错';
                        break;
                    case 501:
                        errorInfo.message = '网络未实现';
                        break;
                    case 502:
                        errorInfo.message = '网络错误';
                        break;
                    case 503:
                        errorInfo.message = '服务不可用';
                        break;
                    case 504:
                        errorInfo.message = '网络超时';
                        break;
                    case 505:
                        errorInfo.message = 'http版本不支持该请求';
                        break;
                    default:
                        errorInfo.message = `连接错误${errorInfo.status}`
                }
            }
            let falg = !errorInfo.status || errorInfo.status == 401;//网路错误 或者 401 就重新登录
            Dialog({message: falg?'网络错误,请稍后再试':`${errorInfo.status}：${errorInfo.message}`}).then(() => {
                if (falg) {
                    console.log(falg)
                    // removeToken("token");
                    // router.replace({
                    //     path: '/login',
                    //     query: {}
                    // });
                    Toast.clear()
                }
                return Promise.reject(error)
            });
            return Promise.reject(error)
        })
    }
    request(options) {  
        var date = new Date() * 1;
        var defaultParams = {...this.defaultParams};
        defaultParams.validTime = date;
        defaultParams.sign = md5(`${defaultParams.appId}|${date}|${defaultParams.appKey}`);    
        const instance = axios.create();
        if(options.method == 'get'){
            options.params = {...options.params,...defaultParams}
        }else{
            options = Object.assign(this.getInsideConfig(), options,{data: `${options.data}${options.data &&　'&' || ''}${qs.stringify(defaultParams)}`});
        }
        this.interceptors(instance, options.url);
        return instance(options)
    }
    get(url, params) {
        var options = {
            method: 'get',
            url,
            params
        };
        console.log(options)
        return this.request(options)
    }
    getPdf(url, params) {
        let options = {
            method: 'get',
            url,
            params,
            
        };
        return this.request(options)
    }
    post(url, data) {
        let options = {
            method: 'post',
            url,
            data: qs.stringify(data),
        };
        return this.request(options)
    }
    postJson(url, data) {
        let options = {
            method: 'post',
            url,
            data,
            headers: {
                'content-type': 'application/json'
            }
        };
        return this.request(options)
    }
    put(url, data) {
        let options = {
            method: 'put',
            url,
            data: qs.stringify(data)
        };
        return this.request(options)
    }
    delete(url, params) {
        let options = {
            method: 'delete',
            url,
            params
        };
        return this.request(options)
    }
    upload(url, data) {
        // var tokenuc = getToken();
        let options = {
            method: 'post',
            url,
            data,
            // headers: {
            //     'Authorization': 'bearer ' + tokenuc
            // }
        };
        return this.request(options)
    }
    postJsonFile(url, data) {
        let options = {
            method: 'post',
            url,
            data,
            apiCode,
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return this.request(options)
    }
    login(url, data) {
        const d = 'CFGJ_H5:autozi_cfgj';
        const str = CusBase64.CusBASE64.encoder(d);
        let options = {
            method: 'post',
            url,
            data: qs.stringify(data),
            headers: {
                'Authorization': 'Basic ' + str
            }
        };
        return this.request(options)
    }
}
export default HttpRequest